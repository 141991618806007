<template>
  <div>
    <h4 class="mb-2">Member Information</h4>
    <b-row class="customer-more-detail">
      <b-col md="4" sm="6" lg="3">
        <div>Member Tier</div>
        <div>{{ form.member_level_name }}</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Point</div>
        <div>
          <!-- <pre>{{ form }}</pre> -->
          {{ form.point_total | numeral("0,0") }}
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Expire Date</div>

        <div v-if="form.expire_date">
          {{ form.expire_date | moment("DD/MM/YYYY") }}
        </div>
        <div v-else>-</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Consent Status</div>
        <div :class="form.is_consent == 1 ? 'text-green' : 'text-error'">
          {{ form.is_consent == 1 ? "Yes" : "No" }}
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4 class="mb-2">Purchase Information</h4>
    <b-row class="customer-more-detail pb-4">
      <b-col md="4" sm="6" lg="3">
        <div>Total Spending</div>
        <div>{{ form.grand_total | numeral("0,0") }} Baht</div>
      </b-col>
      <div class="break-normal"></div>
      <b-col md="4" sm="6" lg="3">
        <div>Last Purchase Date</div>
        <div>
          {{ form.last_purchase_date || "-" | moment("DD/MM/YYYY") }}
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Customer Lifecycle</div>
        <div>{{ form.customer_lifecycle || "-" }}</div>
      </b-col>
      <div class="break-normal"></div>
      <b-col md="4" sm="6" lg="3">
        <div>First Purchase Branch</div>
        <div>{{ form.first_purchase_branch || "-" }}</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>First Purchase Channel</div>
        <div>{{ form.first_purchase_channel || "-" }}</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Last Purchase Branch</div>
        <div>
          <!-- <pre>{{ form }}</pre> -->
          {{ form.last_purchase_branch || "-" }}
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Last Purchase Channel</div>
        <div>{{ form.last_purchase_channel || "-" }}</div>
      </b-col>

      <div class="break-normal"></div>
      <b-col md="4" sm="6" lg="3">
        <div>Recency</div>
        <div class="mt-3 range-wrap">
          <div class="range-value" id="rangeV"></div>
          <div
            class="range-avg"
            id="range-recen-avg"
            :avg="form.recently.average"
          ></div>
          <!-- {{ form.recently }} -->
          <input
            id="range"
            type="range"
            :value="form.recently.value"
            :max="form.recently.max"
            :min="form.recently.min"
            :data-avg="form.recently.average"
            step="0.1"
            disabled
          />
        </div>
        <div class="content-between">
          <small>MIN </small> <small>MAX</small>
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Frequency</div>
        <div class="mt-3 range-wrap">
          <div class="range-value" id="rangeV-freq"></div>
          <div
            class="range-avg"
            id="range-freq-avg"
            :avg="form.frequency.average"
          ></div>
          <input
            id="range-freq"
            type="range"
            :value="form.frequency.value"
            :max="form.frequency.max"
            :min="form.frequency.min"
            :data-avg="form.frequency.average"
            step="0.1"
            disabled
          />
        </div>
        <div class="content-between">
          <small>MIN </small> <small>MAX</small>
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Monetary</div>
        <div class="mt-3 range-wrap">
          <div class="range-value" id="rangeV-mon"></div>
          <div
            class="range-avg"
            id="range-mon-avg"
            :avg="form.monetary.average"
          ></div>
          <input
            id="range-mon"
            type="range"
            :value="form.monetary.value"
            :max="form.monetary.max"
            :min="form.monetary.value == 0 ? 0 : form.monetary.min"
            :data-avg="form.monetary.average"
            step="0.1"
            disabled
          />
        </div>

        <div class="content-between">
          <small>MIN </small> <small>MAX</small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    form: { required: true },
  },
  mounted() {
    this.createTooltipRange("range", "rangeV", "range-recen-avg");
    this.createTooltipRange("range-freq", "rangeV-freq", "range-freq-avg");
    this.createTooltipRange("range-mon", "rangeV-mon", "range-mon-avg");
  },
  methods: {
    selectBestMulp(value) {
      switch (value) {
        case value < 30:
          return 0.25;

        case value < 40:
          return 0.17;

        default:
          return 0.15;
      }
    },
    createTooltipRange(input, label, avg) {
      const range = document.getElementById(input);

      const rangeV = document.getElementById(label);
      const rangeAvg = document.getElementById(avg);
      const avgValue = range.dataset.avg;

      const newValue = Number(
        ((range.value - range.min) * 100) / (range.max - range.min)
      );
      let avgPosition = Number(
        ((avgValue - range.min) * 100) / (range.max - range.min)
      );

      let mulp = this.selectBestMulp(newValue);
      let newPosition = 8 - newValue * mulp;
      if (newValue == 100) newPosition = -5;
      if (newValue == 0) newPosition = 5;

      rangeV.innerHTML = `<span>${range.value}</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
      rangeAvg.style.left = `${avgPosition}%`;
    },
  },
};
</script>

<style lang="scss">
.range-wrap {
  width: 100%;
  position: relative;
}
.range-value {
  position: absolute;
  top: -50%;
}
.range-value span {
  width: 30px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: var(--primary-color);
  color: #fff;
  font-size: 11px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
  top: -5px;
}
.range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid var(--primary-color);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
.range-avg {
  height: 10px;
  width: 10px;
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  background: #cbc1c1;
  z-index: 1;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
.range-avg:before {
  color: rgba(42, 0, 193, 0.182);
  content: "Average (" attr(avg) ") ";
  position: absolute;
  width: auto;
  font-size: 10px;
  color: #bfbfbf;
  height: 20px;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translate(-30%, 100%);
  margin-left: -5px;
  margin-top: -1px;
}
.range-avg:after {
  content: "";
  height: 17px;
  border-left: thin dashed;
  position: absolute;
  overflow: hidden;
  top: 100%;
  border-color: rgb(61 61 61 / 25%);
  left: 50%;
  transform: translate(-50%, 15%);
}
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--primary-color);
  border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background: #fff;
  -webkit-appearance: none;
  margin-top: -3px;
}
.customer-more-detail {
  row-gap: 0.75rem;
  div {
    div:first-child {
      white-space: nowrap;
      color: rgb(159, 159, 159);
      // font-size: 12px;
    }
  }
}
.customer-more-detail {
  // column-gap: 20px;
  row-gap: 10px;
}
</style>
